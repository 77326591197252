<template>
  <div>
    <Header :languagesProp="'English'" />
    <div class="contentBox">
      <!-- banner -->
      <div class="bannerBox">
        <a href="https://www.paisayaar.pk/home" target="_blank">
          <img src="@/assets/image/newHome/jumpPaisayaar.png" alt="" />
        </a>
      </div>
      <!-- Company Vision -->
      <div class="companyBox">
        <p class="remarksText">
          safe, reliable and humanized inclusive financial
        </p>
        <h2>Company Vision</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/vision1.png" alt="" />
            <p>
              Safety, integrity and professional services anchors
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/vision2.png" alt="" />
            <p>
              Professional, efficient, scientific and technological innovation
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/vision3.png" alt="" />
            <p>
              Technology driven financial service innovation to provide accurate
              financial credit services for Pakistani users.
            </p>
          </li>
        </ul>
      </div>
      <!-- about Us -->
      <div class="companyBox aboutBox">
        <p class="remarksText">
          Advantage over others
        </p>
        <h2>Platform Advantages</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/about1.png" alt="" />
            <h5>Service advantages</h5>
            <p>
              Professional, scientific and all-round digital operation
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about2.png" alt="" />
            <h5>Team advantages</h5>
            <p>
              Mature and professional financial, operations and technical teams
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about3.png" alt="" />
            <h5>Safe and secure</h5>
            <p>
              Encrypt your information with encryption algorithms
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about4.png" alt="" />
            <h5>Digital risk control system</h5>
            <p>
              Strong technological innovation capability, digital riskcontrol
              model
            </p>
          </li>
        </ul>
      </div>

      <!-- operation Team -->
      <div class="companyBox teamBox">
        <p class="remarksText">
          Professional service
        </p>
        <h2>Operation Team</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/team1.png" alt="" />
            <h5>A strong and professional technical team</h5>
            <p>
              Constantly learn cutting-edgetechnologies and always pay attention
              to the stability of platform operation
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/team2.png" alt="" />
            <h5>Professional and mature financial team</h5>
            <p>
              Excellent data actuaries and financial actuaries from different
              regions
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/team3.png" alt="" />
            <h5>Excellent and keen operation team</h5>
            <p>
              Constantly understand and learn local policies and regulations
            </p>
          </li>
        </ul>
      </div>

      <img
        src="@/assets/image/newHome/middleBanner.png"
        style="width:100%; margin-top:80px"
        alt=""
      />

      <!-- Contact Us -->
      <div class="companyBox">
        <p class="remarksText">
          Professional service
        </p>
        <h2>Operation Team</h2>
        <div class="contactUs">
          <ul>
            <li>0516011632 - 0516011932</li>
            <li>0518841270 - 0518841289</li>
            <li>0516000480 - 0516000654</li>
            <li>0518700818 - 0518700840</li>
            <li>0518700849 - 0518700872</li>
            <li>0518700874 - 0518700877</li>
            <li>0518700040 - 0518700041</li>
            <li>0518700336 - 0518700340</li>
            <li>0518700582 - 0518700618</li>
            <li>03174997671 - 03174997672</li>
            <li>03178223672 - 03178223676</li>
          </ul>
          <ul>
            <li>051-8700-014</li>
            <li>051-8700-037</li>
            <li>051-8700-097</li>
            <li>051-8700-099</li>
            <li>051-8700-126</li>
            <li>051-8700-135</li>
            <li>051-8700-436</li>
            <li>310-8153-944</li>
          </ul>
          <ul>
            <li>051-111-883-883</li>
            <li>051-884-127-0</li>
            <li>0315-3003-873</li>
            <li>031-7499-6981</li>
            <li>034-0689-9921</li>
            <li>034-0689-9982</li>
            <li>034-0689-9897</li>
          </ul>
          <!-- <img
            class="rightTopImg"
            src="@/assets/image/newHome/concatIcon2.png"
            alt=""
          /> -->
          <img src="@/assets/image/newHome/concatImg.png" alt="" />
        </div>
      </div>

      <img
        src="@/assets/image/newHome/footImg.png"
        style="width:100%;"
        alt=""
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/headerNew.vue";
import Footer from "../../components/foot.vue";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.contentBox {
  .bannerBox {
    position: relative;
    width: 100%;
    height: 610px;
    background: url("../../assets/image/newHome/banner.png") no-repeat;
    background-size: 100% 100%;
    a {
      position: absolute;
      left: 13.3%;
      bottom: 13%;
      width: 310px;
      height: 76px;
    }
  }
  .companyBox {
    padding: 80px 230px 0;
    margin-bottom: 10px;
    h2 {
      font-size: 44px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #2a2a2a;
      margin: 10px 0 40px;
      text-align: left;
    }
    .remarksText {
      font-family: OpenSans, OpenSans;
      font-weight: 400;
      font-size: 24px;
      color: #2ea76a;
      text-align: left;
    }
    ul {
      display: flex;
      // margin-bottom: 85px;
      li {
        box-sizing: border-box;
        position: relative;
        margin-right: 40px;
        width: 440px;
        height: 579px;
        box-shadow: 3px 8px 15px 0px rgba(123, 123, 123, 0.16);
        border-radius: 24px;
        text-align: left;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #747474;
        line-height: 40px;
        &:last-child {
          margin-right: 0;
        }
        p {
          height: 252px;
          padding: 0 32px;
          font-family: OpenSans, OpenSans;
          font-weight: 400;
          font-size: 28px;
          color: #7c7c7c;
          display: flex;
          align-items: center;
        }

        img {
          width: 100%;
          height: 327px;
        }
      }
    }
  }

  .aboutBox {
    ul {
      li {
        width: 328px;
        height: 360px;
        box-shadow: 0 2px 15px 0 rgba(123, 123, 123, 0.16);
        h5 {
          font-family: OpenSans, OpenSans;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          text-align: center;
          margin: 24px 0;
          line-height: 30px;
          padding: 0 22px;
        }
        p {
          font-family: OpenSans, OpenSans;
          font-weight: 400;
          font-size: 19px;
          color: #666666;
          height: auto;
        }
        img {
          width: 72px;
          height: 72px;
          margin: 27px auto 0;
        }
      }
    }
  }

  .teamBox {
    ul {
      li {
        width: 472px;
        box-shadow: none;
        margin: 0;
        h5 {
          font-family: OpenSans, OpenSans;
          font-weight: 600;
          font-size: 32px;
          color: #2a2a2a;
          padding-left: 10px;
        }
        p {
          height: auto;
          padding: 22px 0 0 10px;
          font-size: 24px;
        }
      }
    }
  }

  .dividerBox {
    width: 1400px;
    height: 2px;
    background-color: #dedede;
    margin: 0 auto 107px;
    .contentBox {
      width: 128px;
      height: 2px;
      background: #2ea76a;
      margin: 0 auto;
    }
  }

  .contactUs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    width: 100%;
    img {
      width: 508px;
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      color: #2a2a2a;
      margin-bottom: 8px;
    }
    p {
      font-size: 24px;
      color: #7c7c7c;
      margin-bottom: 32px;
    }

    ul {
      display: block;
      margin-right: 32px;
      li {
        width: auto;
        // width: 582px;
        height: auto;
        box-shadow: none;
        font-size: 24px;
        color: #333333;
        line-height: 52px;
        margin-right: 0;
      }
    }
  }

  .concatBox {
    padding: 0 143px;
    margin: 110px 0 151px;
    h2 {
      font-size: 44px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
    }
    .concatContent {
      display: flex;
      margin-top: 137px;
      padding-left: 190px;
      justify-content: space-between;
    }
    .concatList {
      padding-top: 18px;
      li {
        display: flex;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 42px;
        margin-bottom: 46px;
        img {
          width: 50px;
          height: 50px;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
